body .ck-toolbar{
  border-width: 0 !important;
  color: #FFFFFF !Important;
}

body .ck-toolbar__items{
  color: #FFFFFF !Important;
}

body .ck-reset_all {
  color:  #ffffff !important;
}

body .ck ul {
  padding-left: 20px;
}
body .ck-blurred .ck-editor__top {
  border-width: 0 !important;
  color:  #ffffff !important;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  box-shadow: none !important;
}

.ck.ck-balloon-panel.ck-powered-by-balloon {
    display: none !important;
    margin-left: 100px !important;
    color: red !important;
}

.ck.ck-button.ck-on {
    background: rgba(255, 255, 255, .1) !important;
}

.ck.ck-editor__editable {
  min-height: 169px;
  padding: 0 18px !important;
}

.ck .ck-placeholder {
  font-size: 14px;
  font-weight: 400;
  margin: 16.5px 0;
}

.news-show-editor {
  overflow: visible;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  word-break: break-word;
}
.news-show-editor.active-mobile {
  padding: 0;
  overflow: hidden;
}
@media (max-width: 600px) {
  .news-show-editor {
    padding: 0;
    overflow: hidden;
  }
}
.news-show-editor .hashtag {
  color: #add8e6; /* Assuming $lightBlue is light blue */
}
.news-show-editor blockquote + * {
  margin-bottom: 16px;
}
.news-show-editor h1,
.news-show-editor h2,
.news-show-editor h3,
.news-show-editor h4,
.news-show-editor h5,
.news-show-editor p,
.news-show-editor ol,
.news-show-editor ul,
.news-show-editor table,
.news-show-editor img,
.news-show-editor .columns,
.news-show-editor blockquote {
  margin-bottom: 16px;
}
.news-show-editor .table-container {
  width: 100%;
  overflow: auto;
}
.news-show-editor figure {
  overflow: auto;
}
.news-show-editor li a,
.news-show-editor p a {
  display: inline;
}
.news-show-editor table {
  table-layout: auto;
  width: 100%;
  height: 100%;
  overflow: visible;
  min-width: 700px;
}
@media (max-width: 600px) {
  .news-show-editor table {
    min-width: 700px;
    overflow: auto;
  }
}
.news-show-editor table td,
.news-show-editor table th {
  border: 1px solid #e8e8eb;
  border-radius: 8px;
  padding: 5px;
}
.news-show-editor .ck-editor__editable {
  min-width: auto;
}
.news-show-editor a {
  display: block;
  text-decoration: none;
}
.news-show-editor blockquote {
  padding: 4px 0 4px 20px;
  border-left: 3px solid #add8e6; /* Assuming $lightBlue is light blue */
}
.news-show-editor .columns {
  display: flex;
}
.news-show-editor .columns-2 > div {
  width: 50%;
}
.news-show-editor .columns-3 > div {
  width: 33.3333%;
}
.news-show-editor .columns-4 > div {
  width: 25%;
}
.news-show-editor iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
  height: 100%;
}
.news-show-editor .image {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.news-show-editor .image.stretched {
  max-width: 100%;
}
.news-show-editor .image.big {
  max-width: 75%;
}
.news-show-editor .image.medium {
  max-width: 50%;
}
.news-show-editor .image.small {
  max-width: 25%;
}
.news-show-editor .image.with-border {
  border: 1px solid #e8e8eb;
}
.news-show-editor .image.with-background {
  background-color: #add8e6; /* Assuming $lightBlue is light blue */
}
.news-show-editor .ck-widget.table td.ck-editor__nested-editable.ck-editor__nested-editable_focused,
.news-show-editor .ck-widget.table td.ck-editor__nested-editable:focus,
.news-show-editor .ck-widget.table th.ck-editor__nested-editable.ck-editor__nested-editable_focused,
.news-show-editor .ck-widget.table th.ck-editor__nested-editable:focus {
  outline: none;
  background-color: transparent;
  border-style: solid;
}
.news-show-editor details summary::marker {
  color: #add8e6; /* Assuming $lightBlue is light blue */
}
.news-show-editor details p {
  padding-left: 20px;
}
.news-show-editor .gallery {
  display: flex;
  overflow: auto;
  margin: 0 -15px;
}
.news-show-editor .gallery::-webkit-scrollbar {
  height: 10px;
}
.news-show-editor .gallery-row {
  margin: 0;
  min-width: 750px;
  flex-wrap: nowrap;
}
.news-show-editor .gallery-row a {
  display: block;
  height: 100%;
}
.news-show-editor .gallery-row a img {
  max-width: 250px;
  min-width: 250px;
  min-height: 250px;
  max-height: 250px;
  display: block;
  object-fit: cover;
}
.news-show-editor .cdx-attaches {
  position: relative;
  margin-bottom: 15px;
}
.news-show-editor .cdx-attaches--with-file {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  border: 1px solid #eff0f1;
  border-radius: 7px;
  background: #fff;
}
.news-show-editor .cdx-attaches--with-file .cdx-attaches__file-info {
  display: grid;
  grid-gap: 4px;
  max-width: calc(100% - 80px);
  margin: auto 0;
  flex-grow: 2;
}
.news-show-editor .cdx-attaches--with-file .cdx-attaches__title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  max-width: 90%;
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
}
.news-show-editor .cdx-attaches--with-file .cdx-attaches__size {
  color: #707684;
  font-size: 12px;
  line-height: 1em;
}
.news-show-editor .cdx-attaches--with-file .cdx-attaches__size:after {
  content: attr(data-size);
  margin-left: 0.2em;
}
.news-show-editor .cdx-attaches__title {
  max-width: 100%;
}
.news-show-editor .cdx-attaches__file-icon {
  padding: 9px 15px 9px 5px;
  background-color: rgb(170, 34, 132);
  border-radius: 6px;
  margin-right: 12px;
  font-size: 10px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 1em;
}
.news-show-editor .cdx-attaches__file-icon p {
  margin: 0;
}
.news-show-editor .cdx-attaches__download-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.news-show-editor .cdx-attaches__download-button:hover {
  background-color: transparent;
}

.quill {
  .ql-editor {
    * {
      all: revert;
    }
  }

  .ql-editor p, .ql-editor ol, .ql-editor ul,
  .ql-editor pre, .ql-editor blockquote,
  .ql-editor h1, .ql-editor h2, .ql-editor h3,
  .ql-editor h4, .ql-editor h5, .ql-editor h6 {
    margin: 0;
  }

  .ql-tooltip {
    z-index: 99;
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: 'Абзац';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: 'Заголовок 1';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: 'Заголовок 2';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: 'Заголовок 3';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: 'Заголовок 4';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: 'Заголовок 5';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: 'Заголовок 6';
  }

  .ql-snow .ql-tooltip a.ql-action::after {
    content: 'Изменить';
  }
  .ql-snow .ql-tooltip a.ql-remove::before {
    content: 'Удалить';
  }
  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    content: 'Сохранить';
  }

  .ql-snow .ql-tooltip::before {
    content: "Введите ссылку:";
  }
}

