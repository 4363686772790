@tailwind base;
@tailwind components;
@tailwind utilities;

@import "assets/styles/calendar.css";
@import "assets/styles/editor.css";

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "rf_dewi_expanded";
  src: url("/src/assets/fonts/rf_dewi_font.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/public/fonts/roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/public/fonts/roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/public/fonts/roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@layer base {
  img {
    @apply inline-block;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
  display: none;
}

.slick-slide div {
  outline: none;
}

.slick-dots ul li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-container .slick-dots {
  bottom: 50px;
  width: 100px;
  right: 2px;
  margin-bottom: 15px;
}

body::-webkit-scrollbar {
  width: 11px;
}
body::-webkit-scrollbar-track {
  background: #ffffff;
}
body::-webkit-scrollbar-thumb {
  background: #dadada;
}
.appartment-scroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.appartment-scroll::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 50px;
  margin-bottom: 270px;
}

.appartment-scroll::-webkit-scrollbar-thumb {
  background: #dadada;
  border-radius: 50px;
  width: 5px;
}

.br-container {
  @apply px-4 sm:px-6 md:px-6;
  @apply mx-auto max-w-[1440px];
}

.toolbar-scroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.toolbar-scroll::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 50px;
}

.toolbar-scroll::-webkit-scrollbar-thumb {
  background: #dadada;
  border-radius: 50px;
  width: 5px;
}

.react-datepicker-wrapper {
  width: 100%;
  display: flex;
}

.image-layout {
  &:hover {
    div {
      opacity: 1;
    }
  }
}

.react-select-prefix .react-select-prefix__menu {
  z-index: 11;
}

.react-select-prefix .react-select-prefix__indicator {
  padding: 0;
}

.react-select-prefix .react-select-prefix__value-container--is-multi {
  padding: 0;
}

.react-select-prefix .react-select-prefix__multi-value {
  background-color: transparent;
  margin: 0;
}

.select-bg-transparent .react-select-prefix__control {
  padding: 0;
  background-color: transparent;
  border: 0;
  max-height: 25px;
  min-height: 25px;
}

.react-select-prefix .react-select-prefix__input-container {
  margin: 0;
  padding: 0;
}

.transition-max-height {
  transition: max-height 0.3s ease-in-out;
}

.prevent-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* button navbar */
.bottom-navbar {
  position: fixed;
  bottom: 0;
  padding-bottom: env(safe-area-inset-bottom);
}

/* editor styles */

.ck-content {
  @apply font-normal;
  font-weight: 400;
}
.ck-content strong,
.ck-content b {
  @apply font-bold;
  font-weight: 700;
}
.ck-content a {
  color: blue;
}

.ck-content a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .toolbar-scroll::-webkit-scrollbar {
    width: 4px;
  }
}

/* auth card stype */

@media screen and (max-width: 370px) {
  .auth-card {
    width: 310px;
  }
}

.is__right {
  .react-select-prefix__menu {
    right: 0;
  }
}

/* notification */
@media screen and (max-width: 350px) {
  .notification-content {
    width: 180px;
  }
}
