.toggle-container {
  position: relative;
  display: flex;
  padding: 3.5px;
  background-color: #f4f4f4;
  border-radius: 32px;
}

.toggle-button {
  flex: 1;
  text-align: center;
  padding: 11px;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  z-index: 1;
  border-radius: 32px;
}

.toggle-bg {
  position: absolute;
  top: 3px;
  bottom: 3px;
  left: 3px;
  width: 50%;
  background-color: #ffffff;
  border-radius: 32px;
}

.toggle-bg-blue {
  color: #ffffff;
  background-color: #37465B;
}
